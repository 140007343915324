$(function (){
    $('.language-switcher').on('click', function (event){
        event.preventDefault()
        $.ajax({
            url: $(this).attr('data-route'),
            data: {
                'language' : $(this).attr('data-language-slug')
            },
        }).done(function(response) {

            if (response.status === 'success') {
                location.reload()
            }
        });
    })

    attachRemoveCartProductEvent()

    $('#footer-search-button').on('click', function () {
        $('#footer-search-form').submit()
    })
})
